import React from 'react'
import ContactForms from './widget/ContactForms'

const WhyData = [
    {
        name: "Quick and top-notch electrical solutions for your needs.",
    },
    {
        name: "Top service provided by a highly skilled team of electricians.",
    },
    {
        name: "We serve each client with professionalism and personal attention.",
    },
    {
        name: "We're committed to your electrical needs with precision and excellence",
    },
    {
        name: "We're your go-to for all electrical needs, with everything from wires to switches.",
    },
    {
        name: "Your one-stop destination for all your electrical needs.",
    },
]

export default function WhyUs() {
    return (
        <div>
            <section className="overflow-hidden bg-white py-8 sm:py-16">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <h2 className="text-2xl font-semibold leading-7 text-red-600">Why Choose Us</h2>
                                {/* <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">An Enterprise API</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">We've built an API that allows you to scale your podcast
                                    production workflow.
                                </p> */}

                                <dl className="mt-10 max-w-xl space-y-2 text-base leading-7 text-gray-600 lg:max-w-none">

                                    {WhyData.map((wdata) => (
                                        <div key={wdata.name} className="relative pl-9">
                                            <p className="flex items-center -mx-2 text-gray-700 dark:text-gray-200">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                <span className="mx-2">{wdata.name}</span>
                                            </p>
                                        </div>
                                    ))}
                                </dl>

                            </div>
                            <div className="mt-10 flex items-center gap-x-6">
                                <button type="button" data-modal-toggle="contact-modal" data-modal-target="contact-modal">
                                    <div
                                        className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                                        Schedule a call
                                    </div>
                                </button>
                                {/* invoking contact modal */}
                                <ContactForms />
                            </div>
                        </div>
                        <img src="https://images.pexels.com/photos/8853510/pexels-photo-8853510.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Product screenshot" className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" />
                    </div>
                </div>
            </section>
        </div>
    )
}
