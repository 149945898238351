import React from 'react'
import Hero from '../components/Hero'
import Features from '../components/Features'
import About from '../components/About'
import Services from '../components/Services'
import WhyUs from '../components/WhyUs'
import Testimonials from '../components/Testimonials'
import Contact from '../components/Contact'

export default function Home() {
  return (
    <div>
        <Hero />
        <Features />
        <About />
        <Services />
        <WhyUs />
        <Testimonials />
        <Contact />
    </div>
  )
}
