import React from 'react'
import { FaClockRotateLeft, FaIndianRupeeSign   } from "react-icons/fa6";
import { RiShieldCheckLine } from "react-icons/ri";
import { GiRibbonMedal } from "react-icons/gi";

const Fdata = [
    {
        icon: <FaClockRotateLeft size={40} color='red' />,
        name: "Immediate Service Options",
    },
    {
        icon: <RiShieldCheckLine size={40} color='red' />,
        name: "Quality electrical materials from trusted brands",
    },
    {
        icon: <GiRibbonMedal size={40} color='red' />,
        name: "Fully Licensed and Properly Certified",
    },
    {
        icon: <FaIndianRupeeSign  size={40} color='red' />,
        name: "Transparent Pricing - No Hidden Charges",
    },
]

export default function Features() {
    return (
        <div className='p-4'>
            <div className="mt-16 grid divide-x divide-y  divide-gray-700 overflow-hidden  rounded-3xl border text-gray-600 border-gray-700 sm:grid-cols-2 lg:grid-cols-4  lg:divide-y-0 xl:grid-cols-4">
                {Fdata.map((fdata) => (
                    <div key={fdata.name} className="group relative text-center bg-gray-800 transition hover:z-[1] hover:shadow-2xl  hover:shadow-gray-600/10">
                        <div className="relative space-y-8 p-4 flex items-center flex-col justify-center">
                            {fdata.icon}
                            <div className="space-y-2">
                                <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">{fdata.name}</h5>
                                
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}
