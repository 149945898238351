import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './components/header';
import Footer from './components/Footer';
import ContactWidget from './components/widget/ContactWidget';

import Home from './pages/Home';
import ServicePage from './pages/ServicePage';


function App() {
  return (
    <div>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<ServicePage />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      <ContactWidget />
    </div>
  );
}

export default App;
