import React from 'react'

import IMG1 from "../assets/1.png"
import IMG2 from "../assets/2.png"
import IMG3 from "../assets/3.png"
import IMG4 from "../assets/4.png"
import IMG5 from "../assets/5.png"
import IMG6 from "../assets/6.png"
import IMG7 from "../assets/7.png"

const ServiceData = [
    {
        index: "1",
        name: "Residential Electrical Wiring and Installation",
        desc: "This involves planning and wiring homes to power lighting, appliances, and devices.",
        image: IMG1,
        list: <ul class="list-disc list-inside">
            <li>Design and Planning</li>
            <li>Obtaining Permits</li>
            <li>Safety Measures</li>
            <li>Panel and Wiring Installation</li>
            <li>Outlet and Switch Installation</li>
            <li>Lighting Installation</li>
            <li>Grounding and Protection</li>
            <li>Inspections</li>
            <li>Documentation</li>
            <li>Quality Assurance</li>
            <li>Professional Assistance</li>
        </ul>,
    },
    {
        index: '2',
        name: "Industrial Electrical Wiring and Installation",
        desc: "This involves designing and implementing electrical systems for large-scale facilities.",
        image: IMG2,
        list: <ul class="list-disc list-inside">
            <li>Design and Planning</li>
            <li>Regulatory Compliance</li>
            <li>Cable Routing and Selection</li>
            <li>Equipment Wiring</li>
            <li>Control Systems and Instrumentation</li>
            <li>Safety Systems</li>
            <li>Testing, Documentation, and Training</li>
            <li>Maintenance Planning</li>
        </ul>,
    },
    {
        index: '3',
        name: "Commercial Building Electrical Wiring and Installation",
        desc: "This involves strategically designing and wiring systems to meet the unique needs of businesses.",
        image: IMG3,
        list: <ul class="list-disc list-inside">
            <li>Planning and Code Compliance</li>
            <li>Panel Installation</li>
            <li>Wiring and Integration</li>
            <li>Safety Measures</li>
            <li>Customized Solutions</li>
            <li>Efficient and Specialized Work</li>
            <li>Collaboration and Inspection</li>
        </ul>,
    },
    {
        index: '4',
        name: "Low Tension (LT) Electrical Works",
        desc: "This involves installation, maintenance, and management of electrical systems with voltages below 1,000 volts (Residential, Commercial, and Industrial settings).",
        image: IMG4,
        list: <ul class="list-disc list-inside">
            <li>Design and Planning</li>
            <li>Cabling and Wiring</li>
            <li>Panel Installation</li>
            <li>Connection of Loads</li>
            <li>Safety Measures</li>
            <li>Testing and Commissioning</li>
            <li>Documentation</li>
        </ul>,
    },
    {
        index: '5',
        name: "High Tension (HT) Electrical Works",
        desc: "This involves installation, maintenance, and management of electrical systems with voltages exceeding 1,000 volts (Industrial facilities, Power plants, and Large-scale infrastructure projects).",
        image: IMG5,
        list: <ul class="list-disc list-inside">
            <li>System Design and Layout</li>
            <li>Cabling and Installation</li>
            <li>Transformer Installation</li>
            <li>Switchgear Installation</li>
            <li>Safety Measures</li>
            <li>Testing and Commissioning</li>
            <li>Documentation</li>
        </ul>,
    },
    {
        index: '6',
        name: "Electrical Inspection, Consultation and Maintenance",
        desc: "This involves inspection, consultation and maintenance of electrical systems for small/large scale facilities (Residential, Commercial, Industrial settings, Power plants, etc).",
        image: IMG6,
        list: <ul class="list-disc list-inside">
            <li>Thorough Inspections</li>
            <li>Expert Consultation</li>
            <li>Routine Maintenance Checks</li>
            <li>Fault Identification and Correction</li>
            <li>Documentation and Recommendations</li>
        </ul>,
    },
    {
        index: '7',
        name: "Electrical Materials Supply",
        desc: "This includes sourcing and providing a range of electrical materials such as wires, cables, switches, outlets, panels, and other necessary items to meet the requirements of electrical contractors, businesses, or individuals involved in electrical work. The focus is on ensuring a steady and reliable supply of quality electrical materials for construction, repairs, and maintenance within the electrical industry.",
        image: IMG7,
        list: <ul class="list-disc list-inside">
            <li>Wires: High-quality wires for various electrical applications.</li>
            <li>Cables: Durable cables suitable for different projects and environments.</li>
            <li>Switches: A range of switches for controlling electrical circuits.</li>
            <li>Outlets: Various types of outlets to suit specific needs and preferences.</li>
            <li>Panels: Electrical panels for distributing power efficiently and safely.</li>
            <li>Other Necessary Items: Assorted electrical components and accessories to support construction, repairs, and maintenance tasks.</li>

        </ul>,
    },
]

export default function ServicePage() {
    return (
        <div>
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-20  text-center lg:pt-32">

                <h1 class="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
                    <span class="inline-block">Our
                        <span class="relative whitespace-nowrap text-red-600">
                            <svg aria-hidden="true" viewBox="0 0 418 42" class="absolute top-2/3 left-0 h-[0.58em] w-full fill-red-300/70" preserveAspectRatio="none"><path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path></svg>
                            <span class="relative"> Services</span></span>
                    </span>
                </h1>

                <p class="mx-auto mt-9 max-w-2xl text-lg tracking-tight text-slate-700 sm:mt-6">
                    <span class="inline-block">When you want quality service from an experienced crew of electricians, turn to us.</span>
                </p>
            </div>

            <div className="px-24">
                {ServiceData.map((sdata, index) => (
                    <div key={`left_${index}`} className={`flex flex-wrap items-center mt-20 text-left text-center ${index % 2 === 0 ? 'lg:flex-row-reverse' : ''}`}>
                        <div className="w-full md:w-3/5 lg:w-1/2 px-4">
                            <img src={sdata.image} alt="gem" className="inline-block md:w-64 " />
                        </div>
                        <div className="w-full md:w-2/5 lg:w-1/2 px-4 text-center md:text-left lg:pl-12">
                            <h3 className="font-bold mt-8 text-xl md:mt-0 sm:text-2xl text-red-600">{sdata.name}</h3>
                            <p className="sm:text-lg mt-6">{sdata.list}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
