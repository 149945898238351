import ContactForms from "./ContactForms";


export default function ContactWidget() {
    return (
        <div className="contact_float">
            {/* <button id="dropdownTopButton" data-dropdown-toggle="dropdownTop" data-dropdown-placement="top" className="mr-3 mb-3 md:mb-0 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Dropdown top </button> */}
            <button className=" fa fa-solid fa-comments contact-icon" id="dropdownTopButton" data-dropdown-toggle="dropdownTop" data-dropdown-placement="top"></button>
            <div id="dropdownTop" className="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
                <ul aria-labelledby="dropdownTopButton">

                    <li>
                        {/* WhatsApp icon */}
                        <a
                            href="https://wa.me/+919986997699"
                            className="whatsapp_float"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fa fa-whatsapp whatsapp-icon"></i>
                        </a>
                    </li>
                    <li className="call_float">
                        <a href="tel:+919986997699"><i className="fa fa-solid fa-phone call-icon" /></a>
                    </li>

                    <li className="message_float" >
                        <button type="button" data-modal-toggle="contact-modal" data-modal-target="contact-modal">
                            <i className="fa fa-solid fa-envelope message-icon" />
                        </button>
                    </li>
                </ul>
            </div>
            {/* invoking contact modal */}
            <ContactForms />
        </div>
    )
}