import React from 'react'

import { FaHome } from "react-icons/fa";
import { LiaIndustrySolid } from "react-icons/lia";
import { MdApartment } from "react-icons/md";
import { GiElectricalResistance } from "react-icons/gi";
import { GiPowerGenerator } from "react-icons/gi";
import { GrUserWorker } from "react-icons/gr";




const Sdata = [
    {
        name: "Residential Electrical Wiring and Installation",
        desc: "This involves planning and wiring homes to power lighting, appliances, and devices.",
        icon: <FaHome size={30} color='white' />,
    },
    {
        name: "Industrial Electrical Wiring and Installation",
        desc: "This involves designing and implementing electrical systems for large-scale facilities.",
        icon: <LiaIndustrySolid size={30} color='white' />,
    },
    {
        name: "Commercial Building Electrical Wiring and Installation",
        desc: "This involves strategically designing and wiring systems to meet the unique needs of businesses.",
        icon: <MdApartment size={30} color='white' />,
    },
    {
        name: "Low Tension (LT) Electrical Works",
        desc: "This involves installation, maintenance, and management of electrical systems with voltages below 1,000 volts (Residential, Commercial, and Industrial settings).",
        icon: <GiElectricalResistance size={30} color='white' />,
    },
    {
        name: "High Tension (HT) Electrical Works",
        desc: "This involves installation, maintenance, and management of electrical systems with voltages exceeding 1,000 volts (Industrial facilities, Power plants, and Large-scale infrastructure projects).",
        icon: <GiPowerGenerator size={30} color='white' />,
    },
    {
        name: "Electrical Inspection, Consultation and Maintenance",
        desc: "This involves inspection, consultation and maintenance of electrical systems for small/large scale facilities (Residential, Commercial, Industrial settings, Power plants, etc).",
        icon: <GrUserWorker size={30} color='white' />,
    },{
        name: "Electrical Materials Supply",
        desc: "Ramp Electricals Supplies various components and equipment needed for electrical installations, projects, or maintenance.",
        icon: <GrUserWorker size={30} color='white' />,
    },
]

export default function Services() {
    return (
        <div className=''>
            <section className="py-4 bg-slate-50 sm:py-6 lg:py-8">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-3xl font-bold leading-tight text-red-500 sm:text-4xl xl:text-5xl">
                            Our Services </h2>
                        <p className="mt-2 text-base leading-7 text-gray-600 sm:mt-4">
                            When you want quality service from an experienced crew of electricians, turn to Ramp Electricals.
                        </p>
                    </div>
                    <div
                        className="grid grid-cols-1 mt-3 text-center sm:mt-6 sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-3 md:gap-0 xl:mt-4">
                        {Sdata.map((sdata) => (
                            <div key={sdata.name} className="md:p-6 lg:p-8 flex flex-col justify-center items-center">
                                <div className="w-14 h-14 rounded-full bg-red-400 flex justify-center items-center">
                                    {/* <i className="fa-solid fa-chart-column text-3xl text-red-500"></i> */}
                                    {sdata.icon}
                                </div>
                                <h3 className="mt-5 text-xl font-bold text-red-500">{sdata.name}</h3>
                                <p className="mt-5 text-base text-gray-600">{sdata.desc}</p>
                            </div>
                        ))}

                    </div>
                    <div className="mt-10 flex flex-col text-center items-center justify-center">
                        <a href="/services"
                            className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                            View More
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}
