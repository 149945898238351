import React from 'react'
import { useForm, useWatch } from "react-hook-form";

const HeroData = [
    {
        name: "Residential Electrical Wiring and Installation",
    },
    {
        name: "Industrial Electrical Wiring and Installation",
    },
    {
        name: "Commercial Building Electrical Wiring and Installation",
    },
    {
        name: "Low Tension (LT) Electrical Works",
    },
    {
        name: "High Tension (HT) Electrical Works",
    },
    {
        name: "Electrical Inspection, Consultation and Maintenance",
    },
    {
        name: "Electrical Materials Supply",
    },

]
export default function Hero() {

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors, isSubmitSuccessful, isSubmitting },
    } = useForm({
        mode: "onTouched",
    });
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [Message, setMessage] = React.useState("");

    const onSubmit = async (data, e) => {
        console.log(data);
        await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(data, null, 2),
        })
            .then(async (response) => {
                let json = await response.json();
                if (json.success) {
                    setIsSuccess(true);
                    setMessage(json.message);
                    e.target.reset();
                    reset();
                } else {
                    setIsSuccess(false);
                    setMessage(json.message);
                }
            })
            .catch((error) => {
                setIsSuccess(false);
                setMessage("Client Error. Please check the console.log for more info");
                console.log(error);
            });
    };

    return (
        <div>
            <div className="container flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center">
                <div className="w-full lg:w-1/2">
                    <div className="lg:max-w-lg">
                        <h1 className="text-3xl font-semibold tracking-wide text-red-600 dark:text-white lg:text-4xl">
                            Professional Electrical Services
                        </h1>

                        <div className="mt-8 space-y-5">
                            {HeroData.map((hdata) => (
                                <p key={hdata.name} className="flex items-center -mx-2 text-gray-700 dark:text-gray-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>

                                    <span className="mx-2">{hdata.name}</span>
                                </p>
                            ))}

                        </div>
                    </div>

                    <div className="w-full mt-8 bg-transparent border rounded-md lg:max-w-sm dark:border-gray-700 focus-within:border-red-400 focus-within:ring focus-within:ring-red-300 dark:focus-within:border-red-400 focus-within:ring-opacity-40">
                        {/* <form className="flex flex-col lg:flex-row">
                            <input type="email" placeholder="Enter your email address" className="flex-1 h-10 px-4 py-2 m-1 text-gray-700 placeholder-gray-400 bg-transparent border-none appearance-none dark:text-gray-200 focus:outline-none focus:placeholder-transparent focus:ring-0" />

                            <button type="button" className="h-10 px-4 py-2 m-1 text-white transition-colors duration-300 transform bg-red-600 rounded-md hover:bg-red-500 focus:outline-none focus:bg-red-400">
                                Contact Us
                            </button>
                        </form> */}

                        {!isSubmitSuccessful && (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input
                                    type="hidden"
                                    value="f6f47160-2a37-484a-8646-f9b1ce023473"
                                    {...register("access_key")}
                                />
                                <input
                                    type="hidden"
                                    value={`New Email Subscription on Ramp Electricals website`}
                                    {...register("subject")}
                                />
                                <input
                                    type="hidden"
                                    value="Ramp Electricals"
                                    {...register("from_name")}
                                />
                                <input id="email_address"
                                    type="email"
                                    placeholder="Email Address"
                                    name="email"
                                    autoComplete="false" className={`w-3/5 p-3 rounded-l-lg sm:w-2/3 border-red-600 hover:border-red-400 ${errors.email
                                        ? "border-red-600 focus:border-red-600 ring-red-100"
                                        : "border-gray-300 focus:border-red-600 ring-red-100"
                                        }`}
                                    {...register("email", {
                                        required: "Enter your email",
                                        pattern: {
                                            value: /^\S+@\S+$/i,
                                            message: "Please enter a valid email",
                                        },
                                    })}
                                />

                                <button
                                    type="submit"
                                    className="w-2/5 m-auto text-white text-base transition-colors bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-offset-2 focus:ring focus:ring-red-200 p-3 font-semibold rounded-r-lg sm:w-1/3 umami--click--contact-submit">
                                    {isSubmitting ? (
                                        <svg
                                            className="w-4 h-4 text-white animate-spin"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24">
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    ) : (
                                        "Subscribe"
                                    )}
                                </button>
                                {errors.email && (
                                    <span className="mt-1 text-red-600">
                                        <small>{errors.email.message}</small>
                                    </span>
                                )}
                            </form>
                        )}
                        {isSubmitSuccessful && isSuccess && (
                            <>
                                <div className=" grid grid-cols-2 mx-auto">
                                    <svg
                                        width="100"
                                        height="100"
                                        className="text-green-300"
                                        viewBox="0 0 100 100"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M26.6666 50L46.6666 66.6667L73.3333 33.3333M50 96.6667C43.8716 96.6667 37.8033 95.4596 32.1414 93.1144C26.4796 90.7692 21.3351 87.3317 17.0017 82.9983C12.6683 78.6649 9.23082 73.5204 6.8856 67.8586C4.54038 62.1967 3.33331 56.1283 3.33331 50C3.33331 43.8716 4.54038 37.8033 6.8856 32.1414C9.23082 26.4796 12.6683 21.3351 17.0017 17.0017C21.3351 12.6683 26.4796 9.23084 32.1414 6.88562C37.8033 4.5404 43.8716 3.33333 50 3.33333C62.3767 3.33333 74.2466 8.24998 82.9983 17.0017C91.75 25.7534 96.6666 37.6232 96.6666 50C96.6666 62.3768 91.75 74.2466 82.9983 82.9983C74.2466 91.75 62.3767 96.6667 50 96.6667Z"
                                            stroke="currentColor"
                                            strokeWidth="3"
                                        />
                                    </svg>
                                    {/* <h3 className="py-5 text-2xl text-green-500">Success</h3> */}
                                    {/* <div className="text-white text-base">{Message}</div> */}
                                    <button
                                        className="mt-6 text-xl text-red-600 focus:outline-none"
                                        onClick={() => reset()}>
                                        Go back
                                    </button>
                                </div>
                            </>
                        )}

                        {isSubmitSuccessful && !isSuccess && (
                            <div className="flex flex-col items-center justify-center text-center text-white rounded-md">
                                <svg
                                    width="97"
                                    height="97"
                                    viewBox="0 0 97 97"
                                    className="text-red-400"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M27.9995 69C43.6205 53.379 52.3786 44.621 67.9995 29M26.8077 29L67.9995 69M48.2189 95C42.0906 95 36.0222 93.7929 30.3604 91.4477C24.6985 89.1025 19.554 85.6651 15.2206 81.3316C10.8872 76.9982 7.44975 71.8538 5.10454 66.1919C2.75932 60.53 1.55225 54.4617 1.55225 48.3333C1.55225 42.205 2.75932 36.1366 5.10454 30.4748C7.44975 24.8129 10.8872 19.6684 15.2206 15.335C19.554 11.0016 24.6985 7.56418 30.3604 5.21896C36.0222 2.87374 42.0906 1.66667 48.2189 1.66667C60.5957 1.66667 72.4655 6.58333 81.2172 15.335C89.9689 24.0867 94.8856 35.9566 94.8856 48.3333C94.8856 60.7101 89.9689 72.58 81.2172 81.3316C72.4655 90.0833 60.5957 95 48.2189 95Z"
                                        stroke="CurrentColor"
                                        strokeWidth="3"
                                    />
                                </svg>

                                <h3 className="text-2xl text-red-400 py-7">
                                    Oops, Something went wrong!
                                </h3>
                                <p className="text-gray-300 md:px-3">{Message}</p>
                                <button className="mt-5 focus:outline-none" onClick={() => reset()}>
                                    Try Again
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex items-center justify-center w-full h-96 lg:w-1/2">
                    <img className="object-cover w-full h-full mx-auto rounded-md lg:max-w-2xl" src="https://images.pexels.com/photos/17842843/pexels-photo-17842843/free-photo-of-a-man-maintaining-an-inverter.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="glasses" />
                </div>
            </div>
        </div>
    )
}
