import React from 'react'

export default function About() {
    return (
        <div>
            <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
                <div className="flex flex-col lg:flex-row justify-between gap-8">
                    <div className="w-full lg:w-5/12 flex flex-col justify-center">
                        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-red-500 pb-4">About Us</h1>
                        <p className="font-normal text-base leading-6 text-gray-600">
                            We are a team of Electrical Engineers with extensive experience as Electrical Contractors, 
                            offering a diverse range of electrical services. Committed to excellence, 
                            we take pride in delivering top-notch solutions for various electrical needs. 
                            Additionally, we are Authorized Dealers for many major brands, 
                            offering a reliable source for all electrical supplies. 
                            Our dedication to quality workmanship and customer satisfaction sets us apart, 
                            making us your trusted partner for comprehensive electrical solutions.
                        </p>
                    </div>
                    <div className="w-full lg:w-8/12">
                        <img className="w-full h-full" src="https://i.ibb.co/FhgPJt8/Rectangle-116.png" alt="A group of People" />
                    </div>
                </div>

                <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
                    <div className="w-full lg:w-12/12 flex flex-col justify-center">
                        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-red-500 pb-4">Our Story</h1>
                        <p className="font-normal text-base leading-6 text-gray-600">
                            Choose Ramp Electricals for top-notch service delivered by a skilled team of electricians. Our seasoned
                            professionals are well-equipped to handle a diverse range of projects and repairs. Recognizing the
                            importance of prompt and high-quality electrical work, we ensure efficient and effective solutions.
                            At Ramp Electricals, we approach every client with a commitment to professionalism and personalized
                            attention. Regardless of the scale of your project, whether it's substantial or minor, we're dedicated to
                            assisting you in realizing your electrical needs with precision and excellence.
                            At Ramp Electricals, we're not just experts in electrical work; we're also your go-to place for all things
                            electrical. We've got everything you need – from wires and switches to other electrical items. Whether
                            you're a pro or simply exploring, we've got you covered with top-quality supplies to make your projects
                            smooth and hassle-free.
                        </p>
                    </div>
                    {/* <div className="w-full lg:w-8/12 lg:pt-8">
                        <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
                            <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                <img className="md:block hidden" src="https://i.ibb.co/FYTKDG6/Rectangle-118-2.png" alt="Alexa featured " />
                                <img className="md:hidden block" src="https://i.ibb.co/zHjXqg4/Rectangle-118.png" alt="Alexa featured " />
                                <p className="font-medium text-xl leading-5 text-red-500 mt-4">Alexa</p>
                            </div>
                            <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                <img className="md:block hidden" src="https://i.ibb.co/fGmxhVy/Rectangle-119.png" alt="Olivia featured " />
                                <img className="md:hidden block" src="https://i.ibb.co/NrWKJ1M/Rectangle-119.png" alt="Olivia featured " />
                                <p className="font-medium text-xl leading-5 text-red-500 mt-4">Olivia</p>
                            </div>
                            <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                <img className="md:block hidden" src="https://i.ibb.co/Pc6XVVC/Rectangle-120.png" alt="Liam featued " />
                                <img className="md:hidden block" src="https://i.ibb.co/C5MMBcs/Rectangle-120.png" alt="Liam featued " />
                                <p className="font-medium text-xl leading-5 text-red-500 mt-4">Liam</p>
                            </div>
                            <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                <img className="md:block hidden" src="https://i.ibb.co/7nSJPXQ/Rectangle-121.png" alt="Elijah featured " />
                                <img className="md:hidden block" src="https://i.ibb.co/ThZBWxH/Rectangle-121.png" alt="Elijah featured " />
                                <p className="font-medium text-xl leading-5 text-red-500 mt-4">Elijah</p>

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
